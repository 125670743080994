import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import "./potable.css";
import CustomPagination from "../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import PrDetailsUnderJobs from "../../Models/PrDetailsUnderJobs/PrDetailsUnderJobs";
import { useDispatch, useSelector } from "react-redux";
import { fetchPoDataByFilter, updatePoDoc, updateVendorStatus } from "../../Store/Actions/vendor_dashboard";
import PageLoader from "../../Models/Pageloader/PageLoader";
import JobExpandedTablePO from "../Jobs/JobsTable/JobExpandedTablePO";
import { toast } from "react-toastify";

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#103A5D",
      color: "white",
      fontSize: "14px",
      fontFamily: "Sarabun",
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
};


const ExpandedComponent = ({ data }) => (
 
  <pre>
  
    <JobExpandedTablePO data={data.poPrData} PurchasingGroup
={data.PurchasingGroup}/>
  </pre>
);

const PoTable = ({getCount}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState(0);
  const [orderBy, setOrderBy] = useState('PoDbId');
  const [orderDir, setOrderDir] = useState('ASC');
  const [count, setCount] = useState(getCount(0) || 0);
  const handleTypeSelect = (selectedKey) => {
    setCurrentPage(1);
    setType(parseInt(selectedKey)); 
  }
  const handleSort = (column, sortDirection) => {
    const sortableColumns = ['PrDeliveryDate', 'PONumber', 'PCMPersonInCharge'];
    if (sortableColumns.includes(column.selector)) {
      setOrderBy(column.selector);
      setOrderDir(sortDirection.toUpperCase()); 
    }
  };


  const {dashboardPOData:data,loading,error}= useSelector((state) => state.VendorDashboardReducer);


  useEffect(() => {
    if(type===0){
      dispatch(fetchPoDataByFilter(currentPage,itemsPerPage,orderBy,orderDir));
    }
    else{
      dispatch(fetchPoDataByFilter(currentPage,itemsPerPage,orderBy,orderDir,type));
    }
     
    
  }, [dispatch,currentPage,itemsPerPage,orderBy,orderDir,type]);

  useEffect(()=>{
    if (data) {
      setCount(data.TotalCount);
    }
  },[data,count])

const columns = [
  {
    name: `${t("Location")}`,
    selector: (row) => row.poPrData?.[0]?.PRLocation || "N/A",
    sortable: true,
  },
  {
    id:`PrNumber`,
    name: `${t("PR No")}`,
    selector: (row) =>`Combined (${row?.poPrData.length})` ,
    sortable: true,
  },
  {
    id:`PrShortText`,
    name: `${t("PR Title")}`,
    selector: (row) => `Combined (${row?.poPrData.length})`,
    sortable: true,
    // width: "15%",
  },
  {
    name: `${t("PO No")}`,
    selector: (row) => row.PONumber,
    sortable: true,
  },
  {
    name: `${t("Delivery date(PO)")}`,
    selector: (row) => {
      const dates = row?.poPrData.map(item => new Date(item.PrDeliveryDate));
      const maxDate = new Date(Math.max(...dates));
      return maxDate.toLocaleDateString(); 
    },
    sortable: true,
  },

  {
    name: `${t("Buyer")}`,
    selector: (row) => row.PCMPersonInCharge,
    sortable: true,
  },
  {
    id:"Status",
    name: `${t("Status")}`,
    cell: (row) => (
      <button
        className={
          row.VmsStatus === 1
            ? "dashboard-main-page-award Status-confirming-po"
            : row.VmsStatus === 2
            ? "dashboard-main-page-award Status-bidding"
            : ""
        }
      >
        {row.VmsStatus === 1 ? "Confirming PO" : row.VmsStatus === 2 ? "Acknowledge PO" : "Unknown"}
      </button>
    ),
  },
  {
    name: `${t("Action")}`,
    width: "200px",
    cell: (row) => (
      <>
        {row.VmsStatus == 1 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "5px" }}
          >
            <button className="dashboard-main-page-download" onClick={()=>{
              onClickDownload(row)
            }}>Download</button>
            <button
              className="dashboard-main-page-pr-view"
              onClick={(id)=>{
                
                onClickUploadPo(row?.PoDbId)
              }
              }
            >
              Upload
            </button>
          </div>
        ) : row.VmsStatus == 2 ? (
          <button
            className="dashboard-main-page-pr-view"
            onClick={() => {
              handleViewPr(row?.PoDbId);
            }}
          >
            View Po
          </button>
        ) : null}
      </>
    ),
  },
];
function findPoById(dataArray, targetId) {
  const result = dataArray?.data?.find(item => item.PoDbId === targetId);
  return result?.vendorPoAttachments || [];
}



const onClickUploadPo = (id) => {
  
  const result=findPoById(data,id)
    dispatch(updatePoDoc(result));
  navigate(`/upload-po/${id}`);
};

const onClickDownload =(data)=>{
  if(data?.IsPoOver1Million==0){
    navigate(`/po-details/${data?.PoDbId}`);
  }
  else{
    if(data?.adminPoAttachments?.length>0){
      dispatch(updateVendorStatus(data?.PoDbId))
      data?.adminPoAttachments.forEach((attachment)=>{
        window.open(attachment.attachmentUrl, '_blank');
      })
    }
    else{
      toast.error("No attachment available")
    }
  }
}

  const handleViewPr = (id) => {
    // setIsModalOpen(true);
    navigate( `/po-details/${id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // const filteredDataByType =
  //   type === "all" ? data : data.filter((item) => item.status === type);

  // const paginatedData = filteredDataByType.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );
  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
  };

 
  const headerNames = columns.map((column) => column?.name);
  const csvData = Array.isArray(data?.data)
  ? data?.data?.map((item) => {
    
      if (!item || typeof item !== 'object') return {}; 
      return columns.reduce((row, column) => {
        if (column.id === "PrNumber") {
          const prNumbers = Array.isArray(item?.poPrData) 
              ? item.poPrData.map(pr => pr.PrNumber).join(', ') 
              : '';
          return { ...row, [column.name]: prNumbers };
      }
      if (column.id === "PrShortText") {
        const prTitles = Array.isArray(item?.poPrData) 
            ? item.poPrData.map(pr => pr.PrShortText).join(', ') 
            : '';
        return { ...row, [column.name]: prTitles };
    }
    if (column.id === "Status") {
      return { ...row, [column.name]: item.VmsStatus === 1 ? "Confirming PO" : item.VmsStatus === 2 ? "Acknowledge PO" : "" };
  }
      
        const value = column.selector ? column.selector(item) : item[column.name] || '';
        return { ...row, [column.name]: value };
      }, {});
    })
  : [];
  const closeModal = () => {
    setIsModalOpen(false);
  };

  
  
  
  return (
    <>
    {loading && <PageLoader/>}
      {isModalOpen && (
        <PrDetailsUnderJobs
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="PR detail"
        />
      )}
      <div className="dahsboard-main-page-tables">
        <Nav activeKey="/home" onSelect={handleTypeSelect}>
          <Nav.Item>
            <Nav.Link
              className={
                type === 0 ? "po-table-nav-active" : "po-table-nav"
              }
              eventKey="0"
            >
              {t("All")}
              <span
                className={
                  type === 0 ? "po-top-badge-active" : "po-top-badge"
                }
                eventKey="0"
              >
                 {getCount(0)}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === 1
                  ? "po-table-nav-active"
                  : "po-table-nav"
              }
              eventKey="1"
            >
              {t("Confirming PO")}
              <span
                className={
                  type === 1
                    ? "po-top-badge-active"
                    : "po-top-badge"
                }
                eventKey="1"
              >
                {getCount(1)}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === 2 ? "po-table-nav-active" : "po-table-nav"
              }
              eventKey="2"
            >
              {t("Acknowledge")}
              <span
                className={
                  type === 2 ? "po-top-badge-active" : "po-top-badge"
                }
                eventKey="2"
              >
                 {getCount(2)}
              </span>
            </Nav.Link>
          </Nav.Item>
          
        </Nav>

        <div className="d-flex justify-content-between align-items-center p-3">
          <div>
            <div className="">
              <label className="show-po-table-top" for="">
                {t("Show")}
              </label>
              <select
                className="show-select-options px-2 mx-2"
                id=""
                name=""
                value={itemsPerPage}
                onChange={handleDropdownChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <label className="entries-po-table-top">{t("Entries")}</label>
            </div>
          </div>
          <div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="po_table_data.csv"
            />
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            className="vendor-dashboard-pr-table"
            columns={columns}
            data={data?.data || []}
            customStyles={customStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows={true}
            expandableRowDisabled={(row) => !row}
            expandableRowExpanded={(row) => row}
            expandableRowsComponent={ExpandedComponent}
            onSort={handleSort}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center pb-5">
        <p className="table-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          innerPagination={true}
        />
      </div>
    </>
  );
};

export default PoTable;
