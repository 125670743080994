import React, { useState, useEffect } from "react";
import "./header.css";
import i18next from "i18next";
import { AiOutlineHome } from "react-icons/ai";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AgcLogo from "../../Assets/Images/agc_logo.png";
import thaiFlag from "../../Assets/Svg/thaiFlag.svg";
import usFlags from "../../Assets/Svg/englishFlag.svg";
import dropDownIcon from "../../Assets/Svg/drop-down-icon.svg";

const Header = () => {
  const [vendorShow, setVendorShow] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("vms_vendor_token");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
  };

  const handleShow = () => {
    setVendorShow(!vendorShow);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDashboard = () => {
    navigate("/dashboard");
  };

  const handleProfile = () => {
    navigate("/vendor-profile");
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleLogo = () => {
    navigate("/");
  };

  return (
    <>
      <div className="container pt-4">
        <nav className="navbar navigation">
          <div className="container">
            <div>
              <img className="logo" src={AgcLogo} alt="" onClick={handleLogo} />
            </div>
            <div className="d-flex">
              <div
                className="d-flex align-items-center"
                style={{ gap: "10px" }}
              >
                <AiOutlineHome className="icon " />
                <span className="px-2 navheader">
                  {t("AGC VINYTHAI'S website")}
                </span>
              </div>

              {token ? (
                <div
                  className="dropdown px-3 d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="dropdown-toggle"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ display: "contents" }}
                  >
                    <BsFillPersonCheckFill className="icon" />
                    <span className="px-1 navheader">{t("Vendor")} </span>
                  </span>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div style={{ width: "170px" }}>
                      <ul className="list-group list-group-flush">
                        <li
                          className="list-group-item"
                          onClick={handleDashboard}
                         
                        >
                          {t("Vendor DashBoard")}
                        </li>
                        <li className="list-group-item" onClick={handleProfile}>
                          {t("Vendor Profile")}
                        </li>
                        <li className="list-group-item" onClick={handleLogout}>
                          {t("Logout")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <BsFillPersonCheckFill className="icon" />
                  <span className="px-2 navheader">{t("Vendor")}</span>
                </div>
              )}

              <div
                className="dropdown d-flex align-items-center"
                style={{ width: "100px" }}
              >
                <span
                  className="dropdown-toggle px-0 d-flex align-items-center"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                >
                  {selectedLanguage == "thai" ? (
                    <img src={thaiFlag} alt="thai" style={{ width: "35px" }} />
                  ) : (
                    <img src={usFlags} alt="us" style={{ width: "35px" }} />
                  )}
                </span>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <div>
                    <ul
                      className="list-group list-group-flush"
                      value={localStorage.getItem("i18nextLng")}
                    >
                      <li
                        className="list-group-item"
                        onClick={() => handleClick("en")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={usFlags} alt="" /> &nbsp; English
                      </li>
                      <li
                        className="list-group-item"
                        onClick={() => handleClick("thai")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={thaiFlag} alt="" /> &nbsp; Thai
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;