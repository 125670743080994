import { Dashboard, Home } from "@mui/icons-material";
// import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptLongIcon from "../../Assets/Images/ReceiptLongIcon.svg";
import VendorFollowWhite from "../../Assets/Svg/VendorFollowWhite.svg";

import Person2Icon from "@mui/icons-material/Person2";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Assets/Images/Frame1196.svg";
import "./sidebar.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  updatedDataFifth,
  updatedDataFirst,
  updatedDataFourth,
  updatedDataSecond,
  updatedDataSixth,
  updatedDataThird,
} from "../../Store/Reducer/registrationReducer";
import { clearVendorInfo } from "../../Store/Actions/vendor_info";
import { store } from "../../Store/store";

const SideNavBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const activeRoutes = [
    "po-table",
    "pr-table",
    "dashboard",
    "po-details",
    "upload-po",
  ];

  const isLinkActive = activeRoutes.some((route) => pathname.includes(route));

  const dashboardClass = `my-2 sidenavheader ${
    isLinkActive ? "link-active" : ""
  }`;

  const onClickVendorFollow = () => {
    navigate("/vendor-follow");
  };

  const onClickVendorProfile = () => {
    navigate("/vendor-profile");
  };

  const onClickDashboard = () => {
    navigate("/dashboard");
  };

  const handleLogout = () => {
    localStorage.clear();

    dispatch(updatedDataFirst({}));
    dispatch(updatedDataSecond({}));
    dispatch(updatedDataThird({}));
    dispatch(updatedDataFourth({}));
    dispatch(updatedDataFifth({}));
    dispatch(updatedDataSixth({}));
    store.dispatch(clearVendorInfo());
    navigate("/");
  };
  return (
    <>
      <div className="d-flex flex-column flex-shrink-0 py-3 px-4 text-white sidebar">
        <p className="dashboard">
          <div className="dashboard-top  py-4 my-4 mx-3">
            <img src={Logo} alt="" style={{ width: "150px" }} />
          </div>
        </p>

        {/* <hr /> */}
        <ul
          className="nav nav-pills flex-column mb-auto"
          style={{ borderBottom: "none" }}
        >
          <li className="nav-item ">
            <p
              className={dashboardClass}
              onClick={onClickDashboard}
              // style={{ cursor: "not-allowed" }}
              aria-current="page"
            >
              <Home style={{ marginRight: "7px" }} /> {t("Vendor DashBoard")}
            </p>
          </li>
          <li className="my-3">
            <p
              onClick={onClickVendorProfile}
              className={` sidenavheader ${
                pathname === "/vendor-profile" ? "link-active" : ""
              }`}
            >
              <Person2Icon style={{ marginRight: "7px" }} />{" "}
              {t("Vendor Profile")}
            </p>
          </li>
          <li>
            <p
              onClick={onClickVendorFollow}
              // style={{ cursor: "not-allowed" }}
              className={`sidenavheader ${
                pathname === "/vendor-follow" ? "link-active" : ""
              }`}
            >
              {/* <ReceiptLongIcon style={{ marginRight: "7px" }} />{" "} */}
              <img
                src={
                  pathname === "/vendor-follow"
                    ? ReceiptLongIcon
                    : VendorFollowWhite
                }
                style={{ marginRight: "7px" }}
              />{" "}
              {t("Vendor Follow")}
            </p>
          </li>

          <li className="my-3">
            <p
              onClick={handleLogout}
              className={`sidenavheader ${
                pathname === "/logout" ? "link-active" : ""
              }`}
            >
              <ExitToAppIcon style={{ marginRight: "10px" }} />
              {t("Logout")}
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideNavBar;